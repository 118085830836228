import React from 'react'
import ui from './UI-Images/owner (1).jpg'

function AboutUs() {
  return (
    <>
    <section class="about" aria-labelledby="about-label" >
        <div class="container client-container">

         

          <div class="about-content">

            <p class="section-subtitle" id="about-label">About Us</p>

            <h2 class="h2 section-title">
              We bring solutions to make life easier for our clients.
            </h2>
          
            <ul>

              <li class="about-item">
                <div class="accordion-card expanded" data-accordion>


                  <p class="accordion-content">
                  Established as a Sole Proprietorship firm in the year 2013, we “Agarwal Engineering” are a leading Manufacturer of a wide range of Crush Machine, Textile Machine, Embossing Machine and many more. Situated in Surat (Gujarat, India), we have constructed a wide and well functional infrastructural unit that plays an important role in the growth of our company. We offer these products at reasonable rates and deliver these within the promised time-frame. Under the headship of “Mr. Surendra Agarwal” (Owner), we have gained a huge clientele across the nation. 
                  </p>

                </div>
              </li>


            </ul>

          </div>
          <figure class="about-banner about-image" >
          <img src={ui}   loading="lazy" alt="about banner"
            class="w-100"/>
        </figure>

        </div>
      </section>

    </>
  )
}

export default AboutUs
