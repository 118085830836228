import React from 'react'

function OurClientsNew() {
  return (
    <section class="section service" aria-labelledby="service-label">
        <div class="container">

          <p class="section-subtitle" id="service-label">Our Clients</p>

          <h2 class="h2 section-title">
          Our Esteemed Clients in the Textile Realm
          </h2>

          <ul class="grid-list">

            <li>
              <div class="service-card">

                <h3 class="h4 card-title">International based</h3>

                <p class="card-text">
                Vishnu Processing Industries, Nepal
                    <br/>
                    Shree Siddhi Textile, Nepal
                </p>

              </div>
            </li>

            <li>
              <div class="service-card">

                <h3 class="h4 card-title">National based</h3>

                <p class="card-text" style={{alignItems:""}}>
                RGM textiles, Hyderabad
                    <br/>
                    Gopishankar Krishna Processor, Amritsar
                    <br/>
                    Tirupati Dying & Printing Mills, Masoori
                    <br/>
                    Mahamantra Fabric, Mehsana
                    <br/>
                    Kriploan Synthetic, Tirupur
                    <br/>
                    AS Fashion, Mumbai

                </p>

              </div>
            </li>

            <li>
              <div class="service-card">

                <h3 class="h4 card-title">Surat Based</h3>

                <p class="card-text">
                    Kirtida Silk Mills
                    <br/>
                    Kiara Mills
                    <br/>
                    Kirtiprada Fashions
                    <br/>
                Indigo Industries
                    <br/>
                    Avichal Weaves
                    <br/>
                    Kiran Deka & Calendaring
                    <br/>
                    Shree Garg Exports
                    <br/>
                    Vista International
                    <br/>
                    Savitri Weaving
                </p>

              </div>
            </li>
          </ul>

        </div>
      </section>

  )
}

export default OurClientsNew