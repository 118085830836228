import React, { useState } from 'react';
import model1 from '../Product Images/Saree Roll Press Machine/2.webp';
import model2 from '../Product Images/Saree Roll Press Machine/5.webp';
import model3 from '../Product Images/Saree Roll Press Machine/8.webp';
import model4 from '../Product Images/Saree Roll Press Machine/10.webp';
import model5 from '../Product Images/Saree Roll Press Machine/12.webp';
import playButton from '../UI-Images/play-button-svgrepo-com.svg';

function Modal({ videoSrc, closeModal }) {
  return (
    <div
      className="modal"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          maxWidth: '600px',
          borderRadius: '5px',
          width: '80%',
          height: '80%',
          maxHeight: '80%',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <button
          style={{
            marginTop: '10px',
            padding: '8px 16px',
            border: 'none',
            backgroundColor: '#f44336',
            color: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}


function SareeRollPressMachine() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeVideoSrc, setActiveVideoSrc] = useState('');

  const openModal = (videoSrc) => {
    setActiveVideoSrc(videoSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setActiveVideoSrc('');
    setModalIsOpen(false);
  };

  return (
    <>
      <section class="section project" aria-labelledby="project-label">
        <div class="container">
          <h1 class="section-subtitle" id="project-label">
            Saree Roll Press Machine
          </h1>
          <h2 class="h2 section-title">This product is used to restore the shine to dull fabrics.</h2>
          <ul class="grid-list">
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={model4}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Saree Roll Press Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Economical</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div class="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/MdKBb3FiRoU')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={model5}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Saree Roll Press Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Semi Automatic</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div class="New-card-button" onClick={() => openModal("https://www.youtube.com/embed/CjKlknMeeec")}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={model2}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Saree Roll Press Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Semi Automatic Heavy Duty</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div class="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/lfQjC4MbOv4')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={model3}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Saree Roll Press Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Semi Automatic with Extension</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div class="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/CB3-G-QV9iI')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={model1}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Saree Roll Press Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Automatic</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div class="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/ZpSpl_jusV0')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>

      {modalIsOpen && <Modal videoSrc={activeVideoSrc} closeModal={closeModal} />}
    </>
  );
}

export default SareeRollPressMachine;
