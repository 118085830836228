import React from 'react'
import playButton from '../UI-Images/play-button-svgrepo-com.svg'
import Economical from '../Product Images/Fabric Embossing Machines/Economical.webp'
import HeavyDuty from '../Product Images/Fabric Embossing Machines/Heavy Duty.webp'

function EmbossMachine() {
  return (
    <>
    <section class="section project" aria-labelledby="project-label">
    <div class="container">

      <h1 class="section-subtitle" id="project-label">Fabric Embossing Machine</h1>

      <h2 class="h2 section-title">
      This product enables you to emboss multiple designs on the fabric with ease
      </h2>

      <ul class="grid-list">
      <li>
            <div class="New-card">
<div class="New-card-img">
<img src={Economical} width="560" height="350" loading="lazy"
                alt="Fabric Embossing Machine" class="img-cover"/>
</div>
<div class="New-card-info">
<p class="New-text-title">Economical</p>
</div>
<div class="New-card-footer">
<span class="New-text-title">Demo Video</span>
<div class="New-card-button" >
<svg width="4rem" height="4rem">
<image href={playButton} width="100%" height="100%" />
</svg>
</div>
</div></div>
        </li>
        <li>
            <div class="New-card">
<div class="New-card-img">
<img src={HeavyDuty} width="560" height="350" loading="lazy"
                alt="Fabric Embossing Machine" class="img-cover"/>
</div>
<div class="New-card-info">
<p class="New-text-title">Heavy Duty</p>
</div>
<div class="New-card-footer">
<span class="New-text-title">Demo Video</span>
<div class="New-card-button" >
<svg width="4rem" height="4rem">
<image href={playButton} width="100%" height="100%" />
</svg>
</div>
</div></div>
        </li>
       
      </ul>
    </div>
  </section>
</>
  )
}

export default EmbossMachine