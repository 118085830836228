import React, { useState } from 'react';
import Manual from '../Product Images/Fabric Rolling Machine/Manual.webp';
import ManualEconomical from '../Product Images/Fabric Rolling Machine/Manual Economical.webp';
import SemiAutomatic from '../Product Images/Fabric Rolling Machine/Semi Automatic.webp';
import Automatic from '../Product Images/Fabric Rolling Machine/Automatic.webp';
import Inspection from '../Product Images/Fabric Rolling Machine/With inspection .webp';
import SemiAutomaticHeavyDuty from '../Product Images/Fabric Rolling Machine/Semi Automatic Heavy Duty.webp'
import playButton from '../UI-Images/play-button-svgrepo-com.svg';

function Modal({ videoSrc, closeModal }) {
  return (
    <div
      className="modal"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          maxWidth: '600px',
          borderRadius: '5px',
          width: '80%',
          height: '80%',
          maxHeight: '80%',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <button
          style={{
            marginTop: '10px',
            padding: '8px 16px',
            border: 'none',
            backgroundColor: '#f44336',
            color: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}


function FabricRollingMachine() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeVideoSrc, setActiveVideoSrc] = useState('');

  const openModal = (videoSrc) => {
    setActiveVideoSrc(videoSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setActiveVideoSrc('');
    setModalIsOpen(false);
  };

  return (
    <>
      <section class="section project" aria-labelledby="project-label">
        <div class="container">
          <h1 class="section-subtitle" id="project-label">Fabric Rolling Machine</h1>
          <h2 class="h2 section-title">This product facilitates effective and accurate fabric rolling</h2>
          <ul class="grid-list">
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img src={ManualEconomical} width="560" height="350" loading="lazy" alt="Fabric Rolling Machine" class="img-cover" />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Manual Economical</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/DKjdmeZQ0kE')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img src={Manual} width="560" height="350" loading="lazy" alt="Fabric Rolling Machine" class="img-cover" />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Manual</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/pm6DgYIYPRs')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img src={SemiAutomatic} width="560" height="350" loading="lazy" alt="Fabric Rolling Machine" class="img-cover" />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Semi Automatic</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/vuMUoDMBvuQ')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img src={SemiAutomaticHeavyDuty} width="560" height="350" loading="lazy" alt="Fabric Rolling Machine" class="img-cover" />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Semi Automatic Heavy Duty</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/vg-ZVGZ4wwM')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img src={Automatic} width="560" height="350" loading="lazy" alt="Fabric Rolling Machine" class="img-cover" />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Automatic</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/-fPGFmEzkC0')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img src={Inspection} width="560" height="350" loading="lazy" alt="Fabric Rolling Machine" class="img-cover" />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Fabric Rolling cum Inspection Machine</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/WH0SRKH0nG0')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {modalIsOpen && <Modal videoSrc={activeVideoSrc} closeModal={closeModal} />}
    </>
  );
}

export default FabricRollingMachine;
