import React, { useState } from 'react';
import playButton from '../UI-Images/play-button-svgrepo-com.svg';
import BabyFelt from '../Product Images/Crush Machine/BabyFelt.webp';
import LongCrush from '../Product Images/Crush Machine/LongCrush.webp';
import DiamondCrush from '../Product Images/Crush Machine/DiamondCrush.webp';
import Broiler from '../Product Images/Crush Machine/Broiler.webp';
import ChotiCrush from '../Product Images/Crush Machine/ChotiCrush.webp'

function Modal({ videoSrc, closeModal }) {
  return (
    <div
      className="modal"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          maxWidth: '600px',
          borderRadius: '5px',
          width: '80%',
          height: '80%',
          maxHeight: '80%',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <button
          style={{
            marginTop: '10px',
            padding: '8px 16px',
            border: 'none',
            backgroundColor: '#f44336',
            color: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}


function CrushMachine() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeVideoSrc, setActiveVideoSrc] = useState('');

  const openModal = (videoSrc) => {
    setActiveVideoSrc(videoSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setActiveVideoSrc('');
    setModalIsOpen(false);
  };

  return (
    <>
      <section class="section project" aria-labelledby="project-label">
        <div class="container">
          <h1 class="section-subtitle" id="project-label">Crush Machine</h1>
          <h2 class="h2 section-title">The primary function of this machine is to facilitate the design process for curtains and sarees.</h2>
          <ul class="grid-list">
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={BabyFelt}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Baby Felt Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Baby Felt Machine</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/CTUX8Wp25JI')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={LongCrush}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Long Crush Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Long Crush Machine</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/Hb2KWEUZiLY')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={DiamondCrush}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Diamond Crush Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Diamond Crush Machine</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/sTicx7ftyQQ')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            {/* <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={ChotiCrush}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Diamond Crush Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Choti Crush Machine</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/VIDEO_ID')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li> */}
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={Broiler}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Broiler"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Broiler</p>
                  <p class="New-text-body">Production: 10000 meters per 12 hrs</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/VIDEO_ID')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {modalIsOpen && <Modal videoSrc={activeVideoSrc} closeModal={closeModal} />}
    </>
  );
}

export default CrushMachine;
