import React, { useState } from 'react';
import playButton from '../UI-Images/play-button-svgrepo-com.svg';
import Economical from '../Product Images/Border Cutting Machine/Economical.webp'
import HeavyDuty from '../Product Images/Border Cutting Machine/Heavy Duty.webp';
import DoubleSided from '../Product Images/Border Cutting Machine/Double Sided.webp'

function Modal({ videoSrc, closeModal }) {
  return (
    <div
      className="modal"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 999,
      }}
    >
      <div
        className="modal-content"
        style={{
          backgroundColor: '#fff',
          padding: '20px',
          maxWidth: '600px',
          borderRadius: '5px',
          width: '80%',
          height: '80%',
          maxHeight: '80%',
        }}
      >
        <iframe
          width="100%"
          height="100%"
          src={videoSrc}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
        <button
          style={{
            marginTop: '10px',
            padding: '8px 16px',
            border: 'none',
            backgroundColor: '#f44336',
            color: '#fff',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          Close
        </button>
      </div>
    </div>
  );
}

function BorderCuttingMachine() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeVideoSrc, setActiveVideoSrc] = useState('');

  const openModal = (videoSrc) => {
    setActiveVideoSrc(videoSrc);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setActiveVideoSrc('');
    setModalIsOpen(false);
  };

  return (
    <>
      <section class="section project" aria-labelledby="project-label">
        <div class="container">
          <h1 class="section-subtitle" id="project-label">Border Cutting Machine</h1>
          <h2 class="h2 section-title">This product enables you to cut the border selvedge of the fabric</h2>
          <ul class="grid-list">
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={Economical}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Border Cutting Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Economical</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/MIl9QjJq0yc')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={HeavyDuty}
                    // width="560"
                    // height="350"
                    loading="lazy"
                    alt="Border Cutting Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Heavy Duty</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/XOxRx1iIaGo')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="New-card">
                <div class="New-card-img">
                  <img
                    src={DoubleSided}
                    width="560"
                    height="350"
                    loading="lazy"
                    alt="Border Cutting Machine"
                    class="img-cover"
                  />
                </div>
                <div class="New-card-info">
                  <p class="New-text-title">Double Sided</p>
                </div>
                <div class="New-card-footer">
                  <span class="New-text-title">Demo Video</span>
                  <div className="New-card-button" onClick={() => openModal('https://www.youtube.com/embed/-d1zr8uhrJA')}>
                    <svg width="4rem" height="4rem">
                      <image href={playButton} width="100%" height="100%" />
                    </svg>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {modalIsOpen && <Modal videoSrc={activeVideoSrc} closeModal={closeModal} />}
    </>
  );
}

export default BorderCuttingMachine;
