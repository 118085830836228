import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from './LOGO-removebg-preview.png'
import HamBurger from "./Hamburger";
import "./Navbar.css";

function Navbar() {
  const location = useLocation();

  const { pathname } = location;

  const splitLocation = pathname.split("/");

  // const iconStyle = {
  //   size: "1.2rem",
  // };
  return (
    <>
      <div className="navContainer" style={{}} >

        <Link
          className={
            splitLocation[1] === "" ? "navTitles activeNav" : "navTitles"
          }
          to="/aboutus"
        >
          <i className="fa-solid fa-house "></i>
          <div> About Us </div>
        </Link>



        <Link
          className={
            splitLocation[1] === "about" ? "navTitles activeNav" : "navTitles"
          }
          to="/Products"
        >
          <i className="fa-solid fa-users "></i>
          <div> Our Products </div>
        </Link>





        <div className="navLogo">
        <Link to='/'> <img src={Logo} alt="Logo" /> </Link>
          
        </div>


        <Link
        className={
          splitLocation[1] === "contact" ? "navTitles activeNav" : "navTitles"
        }
        to="/OurClients"
      >
        <i className="fa-sharp fa-regular fa-id-card"></i>
        <div> Our Clients </div>
      </Link>



        <Link
          className={
            splitLocation[1] === "design" ? "navTitles activeNav" : "navTitles"
          }
          to="/ContactUs" 
        >
          <i className="fa-regular fa-object-group"></i>
          <div> Contact us </div>
        </Link>



       
        <div className="hamBurger">
          <HamBurger />
        </div>
      </div>
    </>
  );
}

export default Navbar;