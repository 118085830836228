import React from 'react'
import './App.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './Components/Home'
import Products from './Components/Products'
import Footer from './Components/Footer'
import AboutUs from './Components/AboutUs'
import OurClientsNew from './Components/OurClientsNew'
import SareeRollPressMachine from './Components/Products/Saree Roll Press Machine'
import ContactUs from './ContactUs'
import Navbar from './Components/Navbar'
import FabricRollingMachine from './Components/Products/FabricRollingMachine'
import FoilTransferMachine from './Components/Products/FoilTransferMachine'
import BorderCuttingMachine from './Components/Products/BorderCuttingMachine'
import EmbossMachine from './Components/Products/EmbossMachine'
import CrushMachine from './Components/Products/CrushMachine'
import MSCalendar from './Components/Products/MSCalendar'
import PleatingMachine from './Components/Products/PleatingMachine'



const App = () => {
  return (
    <>
    
    <BrowserRouter>
    <Navbar/>
    <Routes>
      <Route exact path='/' element={<Home/>}/>
      <Route exact path='aboutus' element={<AboutUs/>}/>
      <Route exact path='Products' element={<Products/> }/>
      <Route exact path='SareeRollPressMachine' element={<SareeRollPressMachine/>}/>
      <Route exact path='FabricRollingMachine' element={<FabricRollingMachine/>}/>
      <Route exact path='FoilTransferMachine' element={<FoilTransferMachine/>}/>
      <Route exact path='BorderCuttingMachine' element={<BorderCuttingMachine/>}/>
      <Route exact path='EmbossMachine' element={<EmbossMachine/>}/>
      <Route exact path='CrushMachine' element={<CrushMachine/>}/>
      <Route exact path='MSCalendarMachine' element={<MSCalendar/>}/>
      <Route exact path='PleatingMachine' element={<PleatingMachine/>}/>
      <Route exact path='OurClients' element={<OurClientsNew/>}/>
      <Route exact path='ContactUs' element={<ContactUs/>}/>
    </Routes>
    <Footer/>
    </BrowserRouter>
   
     {/* <Home/>
    <Products/>
    <OurClientsNew/> */}
    {/* <SareeRollPressMachine/> */}
    {/* <ContactUs/> */}
    
    </>
  )
}



export default App