import React from 'react'
import logo from './LOGO-removebg-preview.png'
import { Link } from 'react-router-dom'


function Footer() {
  return (
    <>
        
  <footer class="footer" style={{marginTop:'2%'}}>
    <div class="container grid-list">

      <div class="footer-brand">

        <Link to="/" class="logo">
          <img src={logo} width="50%"  alt="Adex home"/>
        </Link>

        {/* <ul class="social-list">

          <li>
            <a href="#" class="social-link">
              <ion-icon name="logo-facebook"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" class="social-link">
              <ion-icon name="logo-twitter"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" class="social-link">
              <ion-icon name="logo-dribbble"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" class="social-link">
              <ion-icon name="logo-instagram"></ion-icon>
            </a>
          </li>

          <li>
            <a href="#" class="social-link">
              <ion-icon name="logo-youtube"></ion-icon>
            </a>
          </li>

        </ul> */}

      </div>

      <ul class="footer-list">

        <li>
          <p class="h4 footer-list-title">Get in Touch</p>
        </li>

        <li>
          <address class="footer-text">
            <a href="https://www.google.com/maps/place/Agarwal+Engineering/@21.1426684,72.8408193,17z/">Plot No.56, Pandesara G.I.D.C, Surat</a>
          </address>
        </li>

        <li>
          <a href="mailto:sales.agarwalengineering@gmail.com" class="footer-link">sales.agarwalengineering@gmail.com</a>
        </li>

        <li>
          <a href="tel:919376249637" class="footer-link">+91 9376249637</a>
        </li>

      </ul>

      <ul class="footer-list">

        <li>
          <p class="h4 footer-list-title">Learn More</p>
        </li>

        <li>
          <Link to="/AboutUs" class="footer-link">About Us</Link>
        </li>

        <li>
          <Link to="/Products" class="footer-link">Our Products</Link>
        </li>

        <li>
          <Link to="/OurClients" class="footer-link">Our Clients</Link>
        </li>

        <li>
          <Link to="ContactUs" class="footer-link">Contact Us</Link>
        </li>

      </ul>

      <ul class="footer-list">

<li>
  <p class="h4 footer-list-title">Our Products</p>
</li>

<li>
  <Link to="/SareeRollPressMachine" class="footer-link">Saree Roll Press Machine</Link>
</li>

<li>
  <Link to="/FabricRollingMachine" class="footer-link">Fabric Rolling Machine</Link>
</li>

<li>
  <Link to="/BorderCuttingMachine" class="footer-link">Border Cutting Machine</Link>
</li>

<li>
  <Link to="/FoilTransferMachine" class="footer-link">Foil Transfer Machine</Link>
</li>

</ul>

    </div>
  </footer>
    </>
  )
}

export default Footer