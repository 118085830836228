import React from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ imageSrc, title, linkTo, description }) {
  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <li>
      <div className="project-card" >
        <figure className="card-banner img-holder" style={{ width: '560', height: '350' }}>
          <img src={imageSrc} width="560" height="350" loading="lazy" alt={title} className="img-cover" />
        </figure>
        <div className="card-content">
          <h3 className="h3" style={{ textAlign: 'center' }}>
            <Link to={linkTo} className="card-title">
              {title}
            </Link>
          </h3>
          <div className="Product-button-container">
            <button className="Product-button">
              <Link to={linkTo}>View More</Link>
            </button>
          </div>
          <p className="card-text">{description}</p>
        </div>
      </div>
    </li>
  );
}

export default ProductCard;