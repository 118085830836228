import React from 'react'
import { Link } from 'react-router-dom'

function Home() {
  return (
    <div className='home-top' style={{height:'100vh'}}>
      <section class="section hero" aria-label="home">
        <div class="container">

          <div class="hero-content">

            <h1 class="h1 hero-title">Agarwal Engineering</h1>

            <p class="hero-text">
            Empowering Textile Success through Innovative Machinery Solutions
            </p>

            <div class="btn-wrapper">

              <Link to="Products" class="btn btn-primary">Our Products</Link>

              <Link to="ContactUs" class="btn btn-outline">Contact Us</Link>

            </div>

          </div>
          

        </div>
      </section>
    </div>
  )
}

export default Home