import React from 'react'
import ProductCard from './ProductCard'
import SareeRollPressMachine from './Slider/SareeRollPressMachine.webp'
import FabricRollingMachine from './Slider/FabricRollingMachine.webp'
import FoilTransferMachine from './Slider/FoilTransferMachine.webp'
import FabricInspectionMachine from './Slider/FabricInpectionMachine.webp'
import BorderCuttingMachine from './Slider/BorderCuttingMachine.webp'
import PleatingMachine from './Slider/PleatingMachine.webp'
import Emboss from './Slider/Emboss.webp'
import CrushMachine from './Slider/CrushMachine.webp'
import MSCalendarMachine from './Slider/MS Calendar Machine.webp'
import { Link, BrowserRouter } from 'react-router-dom'

function Products() {
  const productCards = [
    {
      imageSrc: SareeRollPressMachine,
      title: 'Saree Roll Press Machine',
      linkTo: '/SareeRollPressMachine',
      description: 'This product is used to restore the shine to dull fabrics.',
    },
    {
      imageSrc: FabricRollingMachine,
      title: 'Fabric Rolling Machine',
      linkTo: '/FabricRollingMachine',
      description: 'This product facilitates effective and accurate fabric rolling.',
    },
    {
      imageSrc: FoilTransferMachine,
      title: 'Foil Transfer Machine',
      linkTo: '/FoilTransferMachine',
      description:'This product enables you to tranfer foil on the fabric.',
    },
    {
      imageSrc: FabricInspectionMachine,
      title: 'Fabric Inspection Machine',
      linkTo: '/FabricRollingMachine',
      description:'This product serves the purpose of fabric rolling and inspection simultaneously.',
    },
    {
      imageSrc: BorderCuttingMachine,
      title: 'Border Cutting Machine',
      linkTo: '/BorderCuttingMachine',
      description:'This product enables you to cut the border selvedge of the fabric.',
    },
    {
      imageSrc: PleatingMachine,
      title: 'Pleating Machine',
      linkTo: '/PleatingMachine',
      description:'This machine is specifically designed for creating pleated designs on fabrics commonly used in dresses.',
    },
    {
      imageSrc: Emboss,
      title: 'Fabric Embossing Machine',
      linkTo: '/EmbossMachine',
      description:'This product enables you to emboss multiple designs on the fabric with ease.',
    },
    {
      imageSrc: CrushMachine,
      title: 'Crush Machine',
      linkTo: '/CrushMachine',
      description:'The primary function of this machine is to facilitate the design process for curtains and sarees.',
    },
    {
      imageSrc: MSCalendarMachine,
      title: 'MS Calendar Machine',
      linkTo: '/MSCalendarMachine',
      description:'This machine is utilized to provide a refined appearance to the fabric.',
    },
    // Add more product cards here
  ];
  const handleLinkClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <section className="section project" aria-labelledby="project-label">
        <div className="container">
          <h1 className="section-subtitle" id="project-label">Our Products</h1>
          <h2 className="h2 section-title">Unlocking Textile Potential: Explore Our Cutting-Edge Product Range</h2>
          <ul className="grid-list">
            {productCards.map((card, index) => (
              <ProductCard
                key={index}
                imageSrc={card.imageSrc}
                title={card.title}
                linkTo={card.linkTo}
                description={card.description}
              />
            ))}
          </ul>
        </div>
      </section>
    </>
  );
}

export default Products