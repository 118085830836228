import React from "react";
import { Link } from "react-router-dom";
import "./HamBurger.css";
import Logo from './LOGO-removebg-preview.png'

function HamBurger() {
  function handleUncheck() {
    let ham = document.getElementById("ham");
    if (ham) {
      ham.checked = false;
    } else {
      ham.checked = true;
    }
  }
  return (
    <>
      <div className="hamContainer center">
        <input type="checkbox" name="ham" id="ham" />
        <label id="hamLabel" className="center" htmlFor="ham">
          <span></span>
          <span></span>
          <span></span>
          {/* <span></span> */}
        </label>
        <div className="hamOption">
          <ul className="center">
            <li className="center">
              <img src={Logo} alt="logo" width={120} height={100} />
            </li>
            <li className="center">
              <Link to="/">
                <button className="navBtn" onClick={handleUncheck}>
                  Home
                </button>
              </Link>
            </li>
            <li className="center">
              <Link to="/AboutUs">
                <button className="navBtn" onClick={handleUncheck}>
                  About us
                </button>
              </Link>
            </li>


            <li className="center">
              <Link to="/Products">
                <button className="navBtn" onClick={handleUncheck}>
                  Our Products
                </button>
              </Link>
            </li>

            <li className="center">
              <Link to="/OurClients">
                <button className="navBtn" onClick={handleUncheck}>
                  Our Clients
                </button>
              </Link>
            </li>


            <li className="center">
              <Link  to="/ContactUs">
                <button className="navBtn" onClick={handleUncheck}>
                  Contact Us
                </button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default HamBurger;