import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';

function ContactUs() {
  const form = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); 
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ccwqbo9', 'template_pjx4g62', form.current, '35jN26bbqnKKehHfh')
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
          setIsSubmitted(true);
          setTimeout(() => {
            setIsSubmitted(false);
          }, 10000);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <section style={{ marginTop: '7.8%' }}>
        {isSmallScreen ? (
          <div style={{  marginTop: '20px', width: '100%' }}>
            {/* marginLeft: '10%', */}
            <div className="box-wrapper">
              <div className="box">
                <h2>Contact Us</h2>
                <form id="form" ref={form} onSubmit={sendEmail}>
                  <div>
                    <input
                      type="text"
                      name="Name"
                      placeholder="Name"
                      autoComplete="off"
                      required
                    />
                    <label>Name:</label>
                  </div>
                  <div>
                    <input
                      type="email"
                      name="Email"
                      placeholder="Email"
                      autoComplete="off"
                      pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                    />
                    <label>Email</label>
                  </div>
                  <div>
                    <input
                      type="tel"
                      name="Mobile Number"
                      placeholder="Mobile Number"
                      autoComplete="off"
                      required
                    />
                    <label>Mobile Number</label>
                  </div>
                  <div>
                    <textarea name="message" cols={30} rows={6} autoComplete="off" required></textarea>
                    <label>Message</label>
                  </div>
                  <input id="submit" type="submit" value="Submit" />
                  {isSubmitted && <p>Details submitted successfully!</p>}
                </form>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <div style={{ flex: '1', marginLeft: '10%', marginTop: '20px', width: '100%' }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3721.2444389647158!2d72.84081927468334!3d21.142668380535373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be051ce30709eaf%3A0x47deb6872d7bb25e!2sAgarwal%20Engineering!5e0!3m2!1sen!2sin!4v1689133462203!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: '0', marginTop: '20px' }}
                allowFullScreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>

            <div style={{ flex: '1', marginLeft: '30px', marginTop: '20px', width: '100%'  }}>
              <div className="box-wrapper">
                <div className="box">
                  <h2>Contact Us</h2>
                  <form id="form" ref={form} onSubmit={sendEmail}>
                    <div>
                      <input
                        type="text"
                        name="Name"
                        placeholder="Name"
                        autoComplete="off"
                        required
                      />
                      <label>Name:</label>
                    </div>
                    <div>
                      <input
                        type="email"
                        name="Email"
                        placeholder="Email"
                        autoComplete="off"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
                      />
                      <label>Email</label>
                    </div>
                    <div>
                      <input
                        type="tel"
                        name="Mobile Number"
                        placeholder="Mobile Number"
                        autoComplete="off"
                        required
                      />
                      <label>Mobile Number</label>
                    </div>
                    <div>
                      <textarea name="message" cols={30} rows={6} autoComplete="off" required></textarea>
                      <label>Message</label>
                    </div>
                    <input id="submit" type="submit" value="Submit" />
                    {isSubmitted && <p>Details submitted successfully!</p>}
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default ContactUs;
